<template>
  <v-container class="mt-0 pt-0">
    <v-row class="pr-2">
      <v-col cols="12">
        <v-data-table
          dense
          locale="ru"
          item-key="id"
          single-line
          sort-by="last_active_time"
          :sort-desc="true"
          :search="search"
          :single-select="true"
          :headers="usersHeaders"
          :items="$store.getters.getUsers"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              clearable
              label="Введите имя для поиска или добавьте нового"
              class="mx-4"
              :append-icon="search&&search.length?'mdi-plus':'mdi-magnify'"
              @click:append="addUser"
            ></v-text-field>
          </template>
          <template v-slot:item="{ item }">
            <tr :class="selectedUser == item.id ? 'grey lighten-2' : ''">
              <td>{{ item.id }}</td>
              <td v-if="item.edit != 'name'" @click="editField(item.id, 'name')">
                {{ item.name }}
                <span :style="getLkVersionStyles(item)">{{item.lk_version_txt}}</span>
              </td>
              <td v-if="item.edit == 'name'">
                <v-text-field
                  class="pr-4 mt-4"
                  autofocus
                  dense
                  v-model="item.name"
                  append-icon="mdi-send"
                  @focusout="(e)=>{ if (item.edit) updateField(item.id, 'name', item.oldName) }"
                  @keydown.enter.prevent="(e)=>updateUser(item.id, 'name', e.target.value)"
                  @click:append="updateUser(item.id, 'name', item.name)"
                />
              </td>
              <td v-if="item.edit != 'login'" @click="editField(item.id, 'login')">
                {{ item.login }}
              </td>
              <td v-if="item.edit == 'login'">
                <v-text-field
                  class="pr-4 mt-4"
                  autofocus
                  dense
                  v-model="item.login"
                  append-icon="mdi-send"
                  @focusout="(e)=>{ if (item.edit) updateField(item.id, 'login', item.oldLogin) }"
                  @keydown.enter.prevent="(e)=>updateUser(item.id, 'login', e.target.value)"
                  @click:append="updateUser(item.id, 'login', item.login)"
                />
              </td>
              <td>
                <div class="mt-4 d-flex">
                  <v-text-field
                    class="pr-4"
                    :ref="`pas_${item.id}`"
                    dense
                    v-model="item.password"
                    append-icon="mdi-send"
                    @focusout="(e)=>updateField(item.id, 'password', '********')"
                    @click="if(item.password=='********') item.password = '';"
                    @click:append="updateUser(item.id, 'password', item.password)"
                  />
                  <v-menu offset-y open-on-hover max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs"
                        v-on="on"
                        fab
                        x-small
                        class="pl-0 pr-0 pasBtn"
                        @click="newPassword(item.id)"
                      ><v-icon small>mdi-refresh</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-text>Сгенерировать пароль</v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </td>
              <td v-if="item.edit != 'minActive'" @click="editField(item.id, 'minActive')">
                {{ item.minActive }}
              </td>
              <td v-if="item.edit == 'minActive'">
                <v-menu
                  :close-on-content-click="false"
                  :transition="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pr-4 mt-5"
                      v-model="item.minActive"
                      readonly
                      dense
                      label="Дата начала доступа"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      @keydown.enter.prevent="(e)=>updateUser(item.id, 'minActive', e.target.value)"                      
                    ></v-text-field>
                  </template>
                  <vc-date-picker
                    v-model="item.minActive"
                    :is-range="false"
                    mode="date"
                    locale="ru-RU"
                    @input="item.edit=null; updateUser(item.id, 'minActive', item.minActive)"
                  ></vc-date-picker>
                </v-menu>
              </td>
              <td v-if="item.edit != 'maxActive'" @click="editField(item.id, 'maxActive')">
                {{ item.maxActive }}
              </td>
              <td v-if="item.edit == 'maxActive'">
                <v-menu
                  :close-on-content-click="false"
                  :transition="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pr-4 mt-5"
                      v-model="item.maxActive"
                      dense
                      readonly
                      label="Дата окончания доступа"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      @keydown.enter.prevent="(e)=>updateUser(item.id, 'maxActive', e.target.value)"
                    ></v-text-field>
                  </template>
                  <vc-date-picker
                    v-model="item.maxActive"
                    :is-range="false"
                    mode="date"
                    locale="ru-RU"
                    @input="item.edit=null; updateUser(item.id, 'maxActive', item.maxActive)"
                  ></vc-date-picker>
                </v-menu>
              </td>
              <td v-if="false && item.edit != 'role_lk'" @click="editField(item.id, 'role_lk')">
                {{ drawRole(item.role_lk) }}
              </td>
              <td v-if="false && item.edit == 'role_lk'">
                <v-select
                  v-model="item.role_lk"
                  :items="rolesItems"
                  label=""
                  item-text="name"
                  item-value="id"
                  dense
                  @focusout="(e)=>{ if (item.edit) updateUser(item.id, 'role_lk', item.role_lk) }"
                  @change="updateUser(item.id, 'role_lk', item.role_lk)"
                ></v-select>
              </td><td>
                <span :style="`color: ${getSessionColor(item)}`">{{drawLastActive(item.last_active_time)}}</span>
                <v-menu offset-y open-on-hover v-if="item.session_expire_time && item.session_expire_time>now">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                      v-on="on"
                      fab
                      x-small
                      class="pl-0 pr-0 ml-1"
                      @click="kickUser(item.id)"
                    ><v-icon small color="red">mdi-karate</v-icon></v-btn>
                  </template>
                  <v-card>
                    <v-card-text>Разорвать соединение пользователя</v-card-text>
                  </v-card>
                </v-menu>
              </td>
            </tr></template
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.pasBtn {
   border-radius: 4px !important;
   border: 1px solid rgba(0,0,0,.87)!important;
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Utils from "../services/utils";

export default {
  components: {
  },
  data: () => ({
    rolesItems: [
      {id: "", name: "Пользователь"},
      {id: "administrator", name: "Администратор"},
    ],
    userActive: false,
    selectedUser: null,
    itemsPerPageArray: [5, 10, 25],
    search: "",
    filter: {},
    sortDesc: true,
    page: 1,
    itemsPerPage: 10,
    currentLkVerison: null,
    usersHeaders: [
      { text: "Id", value: "id" },
      { text: "Имя", value: "name" },
      { text: "Login", value: "login" },
      { text: "Пароль", sortable: false },
      { text: "Доступ\u00A0c", value: "minActive" },
      { text: "Доступ\u00A0по", value: "maxActive" },
/*       { text: "Роль", value: "role_lk" }, */
      { text: "Активность", value: "last_active_time" },
    ],
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    numberOfPages() {
      return Math.ceil(this.$store.getters.getUsers.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    ...mapActions(["GetUsers", "SaveUser", "AddUser", "KickUser"]),
    getLkVersionStyles(user) {
      let color = "rgba(0,0,0,.38)";
      if (user.lk_version!==null 
          && this.$store.getters.getCurrentLkVersion!==null
          && user.lk_version < this.$store.getters.getCurrentLkVersion) {
        color = "red";
      }
      return `color: ${color}; margin-left: 10px`;
    },
    getSessionColor(user) {
      if (user.session_expire_time && user.session_expire_time>this.now) return "green";
      return "orange";
    },
    drawLastActive(time) {
      if (!time) {
        return "Никогда";
      }
      const diffDays = moment(this.now, "YYYY-MM-DD HH:mm:ss").diff(moment(time, "YYYY-MM-DD HH:mm:ss"), "days");
      const diffHours = moment(this.now, "YYYY-MM-DD HH:mm:ss").diff(moment(time, "YYYY-MM-DD HH:mm:ss"), "hours");
      //console.log(diffDays, diffHours);

      let days = 1;
      if (diffDays == 0) {
        //console.log(diffHours)
        if (diffHours < 1) return "Только что";
        if (this.now.substring(0, 10) == time.substring(0, 10)) return "Сегодня";
      } else {
        days = diffDays;
      }
      time = Utils.pluralForm(days, "день", "дня", "дней") + " назад";
      return time;
    },
    drawRole(roleId) {
      const roleItem = this.rolesItems.find(item=>item.id==roleId)
      if (!roleItem) return "";
      return roleItem.name;
    },
    generatePassword() {
      const length = 8;
      const charset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let passwd = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        passwd += charset.charAt(Math.floor(Math.random() * n));
      }
      return passwd;
    },
    newPassword(userId) {
      this.$store.commit("clearUsersEdits");
      this.$store.commit("updateUser", {userId, "password": this.generatePassword()});
    },
    async addUser() {
      if (this.search.length) {
        console.log(`Add: ${this.search}`);
        await this.AddUser({ name: this.search });
      }
    },
    async kickUser(userId) {
      await this.KickUser({ id: userId });
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
    editField(userId, field) {
      this.$store.commit("clearUsersEdits");
      this.$store.commit("updateUser", {userId, edit: field});
      if (field == "password") {
        //console.log("ops")
        this.$store.commit("updateUser", {userId, password: ""});
      }
    },
    async updateDates(userId) {
      console.log(userId);
    },
    async updateField(userId, field, value) {
      console.log(userId, field, value);
      this.$store.commit("updateUser", {userId, [field]: value});
      this.editField(userId, null); //reset editing
    },
    async updateUser(userId, field, value) {
      if (field=="maxActive") {
        value = moment(value).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (field=="minActive") {
        value = moment(value).format("YYYY-MM-DD") + " 00:00:00";
      }
    //  console.log(userId, field, value);
      
      this.$store.commit("updateUser", {userId, [field]: value});
      await this.SaveUser({ id: userId, [field]: value});
      this.editField(userId, null); //reset editing
    },
    async toggelUserActive(userId) {
      const user = this.$store.getters.getUsers.find(
        (item) => item.id == userId
      );
      const active = this.$store.getters.getUser(userId).active;
      await this.SaveUser({ id: user.id, active });
      //await this.GetUsers();
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  watch: {
    selectedUser: function (value) {
      console.log(value);
    },
  },
};
</script>
